<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.subtitle
  font-weight: bold
  font-size: 1rem
  color: #777
.ghost
  opacity: 0.5
  // background: #c8ebfb
  // background-color: #fff !important
.dragging
  .flip-list-move
    transition: transform 0.5s
  .btn
    cursor: ns-resize
    background-color: #fff !important

.table-preview
  .sticky-header
    position: sticky
    top: -1px
    background-color: #f0f0f0
    z-index: 1000
    // outline: solid 1px #ccc
    // border-top: solid 1px #ccc
    box-shadow: inset 0px 0px 0px 1px rgba(0,0,0, 0.1), 0 0.3rem 0.5rem rgba(0, 0, 0, 0.05)
    // border-bottom: 0
    color: #333 !important
  tbody
    tr
      td
        color: #555
        &.text-pre
          white-space: pre-line
.block-html
  // opacity: 0.3
  // transition: opacity 0.2s
  // &:hover
  //   opacity: 1
</style>
<template lang="pug">
div
  .d-flex.justify-content-center.align-items-top(style='min-height: 100vh')
    .col-10
      .mb-4
      h3.title.py-3 엑셀로 입력하기
        router-link.btn.btn-light.float-right(:to='`/property/${property_id}/customer/${document_id}/start-from-scratch`') 수기로 입력하기
      //- p(v-show='error')
      //-   h3.subtitle.pb-3 {{error}}
      div.async(:class='{done: done}')
        form.form(@submit.prevent='submit()')
          .pb-4
          .form-group
            label.d-block 1. 엑셀 내용을 복사, 붙여넣기 해주세요.
              a.ml-2(href='#' @click.prevent='fill_sample') 예제입력1
              a.ml-2(href='#' @click.prevent='fill_sample2') 예제입력2
              a.ml-2(href='#' @click.prevent='fill_sample3') 예제입력3 (항목)
              a.ml-2(href='#' @click.prevent='fill_sample4') 예제입력4 (항목 연결)
              a.ml-2(href='#' @click.prevent='fill_sample5') 5
              a.ml-2(href='#' @click.prevent='fill_sample6') 6
            textarea.form-control(v-model='import_data' rows=5 @blur='blur_import_data')

            .pt-1
              //- b-form-checkbox.rounded-0(v-model='apply_header' value='Y' unchecked-value='N') 첫번째 행으로 항목 만들기
              .mt-2
              //- b-form-radio-group(v-model='parse_type' buttons button-variant='radio-btn-outline')
                b-form-radio.btn.btn-default.border(value='rows' :class='{"border-primary": "active"}')
                  span 데이터만 가져오기
                  .pt-2
                  div.mt-1(style='height:5px;')
                    div.mt-1.w-100(style='height:5px; background-color: #ccc')
                  div.mt-1.w-100(style='height:5px; background-color: #ccc')
                  div.mt-1.w-100(style='height:5px; background-color: #ccc')
                b-form-radio.btn.btn-default.border(value='header_rows' :class='{"border-primary": "active"}')
                  span 첫번째 줄 항목 만들기
                  .pt-2
                  div.mt-1(style='height:5px;')
                    div.float-left(style='height:5px; width:30%; background-color: #ccc; margin-right: 3px')
                    div.float-left(style='height:5px; width:30%; background-color: #ccc; margin-right: 3px')
                    div.float-left(style='height:5px; width:30%; background-color: #ccc; margin-right: 3px')
                  div.mt-1(style='height:5px; background-color: #eee')
                  div.mt-1(style='height:5px; background-color: #eee')
              b-form-radio-group(v-model='parse_type')
                b-form-radio.btn.btn-default.bg-white.border(value='rows' style='padding-left: 2rem;')
                  span 데이터만 가져오기
                  .pt-2
                  div.mt-1(style='height:5px;')
                    div.mt-1.w-100(style='height:5px; background-color: #ccc')
                  div.mt-1.w-100(style='height:5px; background-color: #ccc')
                  div.mt-1.w-100(style='height:5px; background-color: #ccc')
                b-form-radio.btn.btn-default.bg-white.border(value='header_rows' style='padding-left: 2rem;')
                  span 첫번째 줄 항목 만들기
                  .pt-2
                  div.mt-1(style='height:5px;')
                    div.float-left(style='height:5px; width:30%; background-color: #ccc; margin-right: 3px')
                    div.float-left(style='height:5px; width:30%; background-color: #ccc; margin-right: 3px')
                    div.float-left(style='height:5px; width:30%; background-color: #ccc; margin-right: 3px')
                  div.mt-1(style='height:5px; background-color: #eee')
                  div.mt-1(style='height:5px; background-color: #eee')
                b-form-radio.btn.btn-default.bg-white.border(value='horizontal_header_rows' style='padding-left: 2rem; width: 230px')
                  span 첫번째 세로줄 항목 만들기
                  .pt-2
                  div.mt-1(style='height:5px;')
                    div.float-left(style='height:5px; width:30%; background-color: #ccc; margin-right: 3px')
                    div.float-left(style='height:5px; width:60%; background-color: #eee;')
                  div.mt-1(style='height:5px;')
                    div.float-left(style='height:5px; width:30%; background-color: #ccc; margin-right: 3px')
                    div.float-left(style='height:5px; width:60%; background-color: #eee;')
                  div.mt-1(style='height:5px;')
                    div.float-left(style='height:5px; width:30%; background-color: #ccc; margin-right: 3px')
                    div.float-left(style='height:5px; width:60%; background-color: #eee;')
            .my-4.ml-4.block-html
              label.d-block.pb-3 HTML 테이블을 쉬운 형식으로 바꾸기
              span.ml-3(style='color: #ccc') 여기에 내용을 붙여넣기 해주세요.
              div.p-2.rounded.mt-1(type='text' contenteditable @paste='paste_input' style='border: dashed 2px #f0f0f0; background-color: rgba(0,0,0,0.02); position: relative; top: -2.2rem; margin-bottom: -2.2rem')
              small: strong {{paste_message}}

          .pb-4
          .form-group.async(:class='{done: (step >= 2)}')
            label.d-block 2. 검토하기를 클릭하여 내용을 확인합니다.
            button.btn.btn-light(type='button' @click='preview') 검토하기
            //- a.ml-4(href='#' @click.prevent='show_parse_option = !show_parse_option' v-show='step == 3') 옵션열기

            //- .bg-light.pb-4
              pre.p-4(v-if='results.errors.length')
                ul
                  li(v-for='e in results.errors')
                    strong {{e.message}}
                    p {{e.code}}:{{e.type}}
              div.bg-light.shadow.rounded-bottom(style='height: 13rem; overflow: scroll;')
                table.table.border.bg-white.table-preview(v-if='results.data[0]' style='table-layout: auto; width: 100%; min-width: 100vw')
                  thead
                    tr
                      th.bg-light.border-0(v-if='apply_header == `N`' v-for='(d, $index) in results.data[0]') {{$index+1}}
                      th.bg-light.border-0(v-if='apply_header == `Y`' v-for='header in parsed_headers')
                        span {{header}}
                        span.text-muted.d-block(v-if='parsed_headers_mapping_by_excel[header]') {{parsed_headers_mapping_by_excel[header]}}
                          b-icon-link45deg
                  tbody
                    tr(v-for='d in results.data')
                      td.text-pre(v-for='datum in d') {{datum}}

                .text-right
                  button.btn.btn-secondary.mr-4(type='button' @click='$modal.hide(`preview`)') 닫기


            //- .mt-2(v-if='show_parse_option && step == 3')
              //- pre {{parsed_h_headers}}
              h5 원하는 항목을 직접 선택해주세요.
              .p-2
                b-form-checkbox-group(v-model='parsed_h_headers_hint' :options='parsed_h_headers')

            //- .mt-2
              div(v-for='e in warning_header')
                p.alert.alert-warning
                  b-icon-exclamation-triangle.mr-2
                  strong.mr-4 알림
                  strong.text-dark {{e.message}}
                  a.ml-1.text-muted.float-right(href='#' style='text-decoration: underline; font-size: 0.8rem' @click.prevent='e.active = true') 클릭하여 항목 연결하기
                  .pl-2(v-show='e.active || parsed_headers_mapping[e.col]')
                    strong 엑셀의&nbsp;
                      code {{parsed_headers_mapping[e.col] || '(선택안됨)'}}&nbsp;
                      | 항목을 &nbsp;
                      code {{e.col}}&nbsp;
                      | 항목에 입력합니다.
                    select.form-control.border-0(v-model='parsed_headers_mapping[e.col]')
                      option(:value='col' v-for='col in parsed_headers') {{col}}
                      //- option(:value='col.key' v-for='col in cols') {{col.label}}
                      //- option(value='SKIP') (불러오지 않음. Skip)
            //- div.bg-light.shadow.rounded-bottom.p-4
            div.async(:class='{done: (step >= 3)}')
              .pb-4
              .p-2
                strong.mr-4.text-primary 성공: {{results.data.length}}건
                strong.mr-4.text-muted 오류: {{results.errors.length}}건

              //- div.bg-light.shadow.rounded-bottom(style='height: 13rem; overflow: scroll')
                table.table.border.bg-white.table-preview(v-if='results.data[0]')
                  thead
                    tr.border-top
                      th.text-center.sticky-header.border-0(v-if='apply_header == `N`' v-for='(d, $index) in results.data[0]') {{$index+1}}
                      th.text-center.sticky-header.border-0(v-if='apply_header == `Y`' v-for='header in parsed_headers') {{header}}
                  tbody
                    tr(v-for='d in results.data')
                      td(v-for='datum in d') {{datum}}
              modal.my-2(name='preview' :width='`90%`' :height='`auto`' :scrollable='true')
                .bg-light.pb-4(v-if='apply_header == `Y`')
                  pre.p-4(v-if='results.errors.length')
                    ul
                      li(v-for='e in results.errors')
                        strong {{e.message}}
                        p {{e.code}}:{{e.type}}

                  .p-2.pl-4
                    strong.mr-4.text-primary 성공: {{results.data.length}}건
                    strong.mr-4.text-muted 오류: {{results.errors.length}}건

                  .bg-white.p-2.px-4.mt-2
                    div(v-for='e in warning_header')
                      p.alert.alert-warning.mb-0
                        b-icon-exclamation-triangle.mr-2
                        strong.mr-4 알림
                        strong.text-dark {{e.message}}
                        a.ml-1.text-primary.float-right(href='#' style='text-decoration: underline; font-size: 0.8rem' @click.prevent='e.active = true') 클릭하여 항목 연결하기
                        .pl-4(v-show='e.active || parsed_headers_mapping[e.col]')
                          strong 엑셀의&nbsp;
                            code {{parsed_headers_mapping[e.col] || '(선택안됨)'}}&nbsp;
                            | 항목을 &nbsp;
                            code {{e.col}}&nbsp;
                            | 항목에 입력합니다.
                          select.form-control.border-0(v-model='parsed_headers_mapping[e.col]')
                            option(:value='col' v-for='col in parsed_headers') {{col}}

                  table.table.border.bg-white.table-preview(v-if='results.data[0]')
                    thead
                      tr
                        th.bg-light.border-0(v-if='apply_header == `N`' v-for='(d, $index) in results.data[0]') {{$index+1}}
                        th.bg-light.border-0(v-if='apply_header == `Y`' v-for='header in parsed_headers')
                          span {{header}}
                            //- span(v-if='document && document.config') {{document.config.cols}}
                          span.text-muted.d-block(v-if='parsed_headers_mapping_by_excel[header]') {{parsed_headers_mapping_by_excel[header]}}
                            b-icon-link45deg
                        //- th.bg-light.border-0 ddsdf
                          //- span(v-if='document && document.config') {{document.config.cols}}
                    tbody
                      tr(v-for='(d, i) in results.data' v-if='i < 30')
                        td.text-pre.p-0(v-for='(datum, j) in d' @click='preview_editing = true' :class='{"py-2 px-2": !preview_editing}')
                          template(v-if='!preview_editing') {{datum}}
                          textarea.border-0.p-2(v-show='preview_editing' v-model='results.data[i][j]' :min-height='100')


                  .text-right
                    button.btn.btn-secondary.mr-4(type='button' @click='$modal.hide(`preview`)') 닫기

                    //- a.float-left.pl-4.text-left(href='#' @click.prevent='show_parse_option = true; $modal.hide(`preview`)')
                      small.text-secondary
                        strong.d-block 원하는 결과물이 아닌가요?
                      small 항목을 수동으로 선택하려면 클릭


          .pb-4
          .pb-4
          div(v-if='apply_header == `N`')
            .form-group(v-show='step >= 3')
              label.d-block 3. 가져온 정보에 알맞는 항목을 선택해주세요.

              table.table(v-if='results.data.length')
                thead
                  tr.shadow-sm.border-top
                    th.border-0.text-center(style='vertical-align: middle') 순서
                    th.border-0.text-center(style='vertical-align: middle') 항목을 선택해주세요.
                    th.border-0.text-center 가져온 정보 미리보기 {{current_page}}번째 (총 {{results.data.length}}건)
                      button.btn.btn-default.text-primary(type='button' @click='prev_page()') 이전
                      button.btn.btn-default.text-primary(type='button' @click='next_page()') 다음
                tbody
                  tr(v-for='(d, $index) in results.data[current_page-1]')
                    th.text-center
                      span.btn.btn-sm {{$index+1}}
                    th
                      select.form-control.border-0(v-model='import_cols[$index]')
                        option(:value='col.key' v-for='col in cols') {{col.label}}
                        option(value='SKIP') 가져오기 않음 (skip)
                    td
                      input.form-control.border-0(type='text' readonly :value='d' tabindex='-1')
            .form-group(v-show='step >= 3')
              .mb-4
              label.async.done 추가 항목이 필요하신가요?
                button.btn.btn-light.text-primary.ml-4(type='button' @click='$modal.show(`table-config`)') 항목 관리 열기

          .clearfix
          .mb-4
          //- pre {{import_cols}}
          .form-group(v-show='step >= 3')
            button.btn.btn-primary.float-right(type='submit' :disabled='saving') {{saved_text}}
            //- router-link.btn.btn-light.text-secondary.float-right.mr-2(:to='`/property/${property_id}/customer/${document_id}`') 뒤로가기
            .py-3.pr-4.float-right
              b-form-checkbox.rounded-0(v-model='apply_upsert' value='Y' unchecked-value='N') @고객아이디 중복되면 내용을 업데이트합니다.
            .clearfix
            .pb-4

      .clearfix
      .mb-4
  modal.modal-default(name='table-config' :width='`90%`' :height='`auto`' :scrollable='true' :clickToClose='false' :shiftY='1')
    .content
      .title 연락처 항목 관리
      .mb-4
      customer-table-config(:property_id='property_id' :document_id='document_id')
        template(v-slot:back)
          button.btn.btn-light.text-secondary.float-right.mr-2(type='button' @click='$modal.hide(`table-config`)') 닫기

</template>

<script>

const Papa = require('papaparse')
const $ = require('jquery')

import CustomerTableConfig from '@/components/CustomerTableConfig'

export default {
  name: 'index',
  props: ['property_id', 'document_id'],
  components: {
    CustomerTableConfig,
  },
  computed: {
    parsed_headers_mapping_by_excel() {
      const a = {}
      for (const k of Object.keys(this.parsed_headers_mapping)) {
        a[this.parsed_headers_mapping[k]] = k
      }
      return a
    },
    // session() {
    //   return this.$store.state.session
    // },
    // property() {
    //   return this.$store.state.property
    // },
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    }
  },
  data() {
    return {
      done: false,
      saving: false,
      saved_text: '여러건 저장하기',

      import_data: '',
      results: {
        data: [],
        errors: [],
        meta: {},
      },
      headers: [],

      current_page: 1,

      step: 1,

      document: {},
      error: '',

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },

      form: {
        customer_id: 'Y',
        customer_name: 'Y',
        customer_phone: 'Y',
        customer_email: 'Y',
      },

      last_col_id: 10,

      cols: [

      ],

      import_cols: {},

      should_save_cols: false,
      apply_header: 'N',
      parsed_headers: [],
      warning_header: [],
      parsed_headers_mapping: {
        '@고객이름': undefined,
        '@고객연락처': undefined,
        '@고객이메일': undefined,
        '@고객아이디': undefined,
      },
      apply_upsert: 'N',

      parse_type: 'rows',
      // parse_type: 'horizontal_header_rows',
      show_parse_option: false,
      parsed_h_headers: [],
      parsed_h_headers_hint: [],

      paste_message: '',

      preview_editing: false,
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    if (this.$store.state.documents) {
      this.load()
    }
    // this.load()
    // this.add_col()

    // this.fill_sample3()
    // this.apply_header = 'Y'
    // this.preview()

    // this.fill_sample4()
    // this.apply_header = 'Y'
    // this.preview()
    // this.parsed_headers_mapping['@고객이름'] = '고객명'
    // this.parsed_headers_mapping['@고객아이디'] = 'user_id'
    // setTimeout(() => {
    //   this.$modal.hide('preview')
    // }, 300);

    // this.fill_sample5()
    // this.preview()
    // this.fill_sample6()
  },
  methods: {
    blur_import_data() {
      if (this.import_data.length) this.step = 2
    },
    paste_input(e) {
      setTimeout(() => {
        // console.log(e.srcElement.getElementsByTagName('td'))
        // const paste_items = []
        // const extract = (el) => {
        //   console.log(el)
        //   for(const e of el) {
        //     if (e.children && e.children.length) continue
        //     if (e.textContent.includes('\t') || e.textContent.includes('\n') || e.textContent.includes(':')) {
        //       e.textContent.split(/\n|\t|\:/g).map(text => {
        //         paste_items.push(text.trim())
        //       })
        //     } else {
        //       paste_items.push(e.textContent.trim())
        //     }
        //   }
        // }
        // // extract(e.srcElement.querySelectorAll('td,li,div,a,dt,dd,span,strong'))
        // extract(e.srcElement.querySelectorAll('*'))
        // this.paste_items = compact(uniq(this.paste_items.concat(paste_items)))
        // this.paste_message = this.paste_items.length + '개 가져왔습니다.'
        // e.srcElement.innerHTML = ''
        this.step = 1

        const table = e.srcElement.querySelectorAll('table')
        console.log(table)
        if (!table.length) {
          this.paste_message = 'table 태그를 찾을 수 없습니다.'
          e.srcElement.innerHTML = ''
          return
        }
        const $table = $(table)

        if (this.parse_type == 'rows' || this.parse_type == 'header_rows') {
          const items = []
          const rows = $table.find('tr')
          // console.log({rows})
          for (const tr of rows) {
            const d = $(tr).children('td').map((i, e) => {
              return $(e).text().trim()
            })
            items.push(d.toArray())
          }
          const t = Papa.unparse(items, {
            delimiter: '\t',
            quotes: true,
            skipEmptyLines: true,
          })
          this.import_data = this.import_data ? this.import_data + '\r\n' + t : t
          this.paste_message = '1건의 가로테이블을 가져왔습니다.'
          this.step = 2
          e.srcElement.innerHTML = ''
        } else if (this.parse_type == 'horizontal_header_rows') {
          const items = []
          const rows = $table.find('tr')

          // convert to array
          const data = rows.map( (i, tr) => {
            const cols = $(tr).children('td, th').map((j, td) => {
              return $(td).text().trim()
            }).map((k, col) => {
              return col
            })
            return cols
          })
          // console.log({data})
          // const pdata = JSON.parse(JSON.stringify(data))
          // console.log({pdata})
          for (const row of data) {
            console.log(row)
            const cols = []
            for (const col of row) {
              cols.push(col)
            }
            items.push(cols)
          }
          console.log({items})

          const t = Papa.unparse(items, {
            delimiter: '\t',
            quotes: true,
            skipEmptyLines: true,
          })
          this.import_data = this.import_data ? this.import_data + '\r\n' + t : t
          this.paste_message = '세로테이블을 가져왔습니다.'
          this.step = 2
          e.srcElement.innerHTML = ''

          // found idx
          // let found_idx = null
          // for (let i=1; i<data.length; i++) {
          //   if (data[i][0] == data[0][0]) {
          //     found_idx = i
          //     break
          //   }
          // }
          // console.log({found_idx})

          // // iterate by chunk
          // if (found_idx) {
          //   // n개

          //   const rows = [
          //     [],
          //   ]

          //   for(let i=0; i<=data.length/found_idx; i++) {
          //     const start = i*found_idx
          //     const end = (i+1)*found_idx
          //     // const chunk = data.slice(start, end)

          //     // set header
          //     if (i == 0) {
          //       for (let k=start; k<end; k++) {
          //         rows[0][k] = data[k][0]
          //       }
          //     }


          //     for (let ri = start; ri<end; ri++) {
          //       if (!data[ri]) continue

          //       for (let j=1; j<data[ri].length; j++) {
          //         const row_offset = i+1
          //         if (!rows[row_offset]) rows[row_offset] = []
          //         rows[row_offset].push(data[ri][j])
          //       }
          //     }
          //   }
          //   // output to text
          //   // this.import_data = rows.map(e => e.join('\t')).join('\n')
          //   const t = Papa.unparse(rows, {

          //   })
          //   this.import_data = t
          //   this.paste_message = (rows.length-1) + '건의 세로테이블을 가져왔습니다.'
          //   this.step = 2
          //   e.srcElement.innerHTML = ''
          // } else {
          //   // 1개 짜리
          //   const rows = [
          //     [],
          //   ]
          //   for (let i=0; i<data.length; i++) {
          //     rows[0][i] = data[i][0]
          //     let last_j = 1
          //     for (let j=1; j<data[i].length; j++) {
          //       if (!rows[j]) rows[j] = []
          //       rows[j].push(data[i][j])
          //     }
          //   }
          //   console.log(rows)
          //   // output to text
          //   // this.import_data = rows.map(e => e.join('\t')).join('\n')
          //   const t = Papa.unparse(rows, {
          //     delimiter: '\t',
          //   })
          //   this.import_data = t
          //   this.paste_message = '1건의 세로테이블을 가져왔습니다.'
          //   this.step = 2
          //   e.srcElement.innerHTML = ''
          // }
        }
      }, 300);
    },
    load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }

      if (!this.document.config) {
        this.document.config = {}
      }
      if (!this.document.config.cols) {
        this.document.config.cols = []
      }
      if (this.document.config.last_col_id) {
        this.last_col_id = this.document.config.last_col_id
      }
      const prev_cols = this.cols.slice()
      const prev_cols_by_key = {}
      for (const col of prev_cols) {
        prev_cols_by_key[col.key] = col
      }
      console.log('>>', prev_cols_by_key)
      this.cols = this.document.config.cols.slice()

      for (const col of this.cols) {
        const f = prev_cols_by_key[col.key]
        if (f) {
          col.value = f.value
        }
      }

      this.done = true
    },
    prev_page() {
      this.current_page = Math.max(1, this.current_page-1)
    },
    next_page() {
      this.current_page = Math.min(this.results.data.length, this.current_page+1)
    },
    fill_sample() {
      this.import_data += `이박사	010-0000-0000	dr.lee@studiointo.asia
김박사	010-1111-1111	dr.kim@studiointo.asia
하박사	010-2222-2222	dr.ha@studiointo.asia\n`
      this.step = 2
    },
    fill_sample2() {
      this.import_data += `김사월	미개봉 중고	99000		99000
크로마티카	미개봉 중고	28000	44000	35000
블루노트 한정판	신품	39400	42900	40000
겨울왕국 2	신품		30000	\n`
      this.step = 2
    },
    fill_sample3() {
      this.import_data += `@고객이름	@고객연락처	@고객이메일	@고객아이디	주문번호	주문메모
이박사	010-0000-0000	dr.lee@studiointo.asia	M1000	3000-3000	무료배송
김박사	010-1111-1111	dr.kim@studiointo.asia	M2000	3000-3001	퀵배송
하박사	010-2222-2222	dr.ha@studiointo.asia	M3000	3000-30002	합배송\n`
      this.step = 2
    },
    fill_sample4() {
      this.import_data += `고객명	@고객연락처	@고객이메일	user_id	주문번호	주문메모
이박사	010-0000-0000	dr.lee@studiointo.asia	M1000	3000-3000	무료배송
김박사	010-1111-1111	dr.kim@studiointo.asia	M2000	3000-3001	퀵배송
하박사	010-2222-2222	dr.ha@studiointo.asia	M3000	3000-30002	합배송\n`
      this.step = 2
    },
    fill_sample5() {
      this.import_data += `"예약번호"	"HQ 0000000"
"여행일"	"2021-03-18"
"여행자"	"테스트 님 1명"
"여행자 정보"	"&lt;span class=&#39;desc-gray&#39;&gt;여행자 1 &lt;/span&gt;
한글 이름 : 테스트
성별 : 여자
생년월일 : 790101"
"주문"	"[이서준 가이드 진행] 오후 2시 투어 (성인) / 수량: 1개"
"여행컨셉"	"혼자 떠나는 여행"
"요청 사항"	""
"총 금액"	"₩ 39,000"
"예약번호"	"HQ 0000000"
"여행일"	"2021-03-18"
"여행자"	"테스트 님 1명"
"여행자 정보"	"&lt;span class=&#39;desc-gray&#39;&gt;여행자 1 &lt;/span&gt;
한글 이름 : 테스트
성별 : 여자
생년월일 : 790101"
"주문"	"[이서준 가이드 진행] 오후 2시 투어 (성인) / 수량: 1개"
"여행컨셉"	"혼자 떠나는 여행"
"요청 사항"	""
"총 금액"	"₩ 39,000"`
      this.parse_type = 'horizontal_header_rows'
      this.step = 2
    },
    fill_sample6() {
      this.import_data += `예약번호	HQ 0000000
여행일	2021-03-18
여행자	ㅇㅇㅇ 님 1명

예약번호	HQ 0000000
여행일	2021-03-18
여행자	ㅇㅇㅇ 님 1명

예약번호	HQ 0000000
여행일	2021-03-18
여행자	ㅇㅇㅇ 님 1명`
      this.parse_type = 'horizontal_header_rows'
      this.step = 2
    },
    preview() {
      this.preview_editing = false

      if (this.parse_type == 'header_rows') {
        this.apply_header = 'Y'
      } else if (this.parse_type == 'horizontal_header_rows') {
        this.apply_header = 'Y'
      } else {
        this.apply_header = 'N'
      }

      const text = this.import_data.trim()
      if (!text || text.length === 0) {
        this.$modal.show('dialog', {
          title: '알림',
          text: '검토할 항목이 없습니다. 1번 항목으로 돌아가 정보를 입력해주세요.',
        })
        this.step = 1
        return
      }

      let config = {}
      if (this.parse_type == 'horizontal_header_rows') {
        config.delimiter = '\t'
      }
      const r = Papa.parse(text, config)
      console.log(r.results)

      if (this.parse_type == 'horizontal_header_rows') {
        this.apply_header = 'Y'
        // console.log('>>>33>', r)
        // check col
        if (r.data[0]) {
          if (r.data[0].length < 2) {
            throw new Error('세로줄 데이터가 없습니다.')
          }
          // const h1 = r.data[0][0]
          let found_idx = null
          // const parsed_h_headers = []
          for (let i=1; i<r.data.length; i++) {
            if (r.data[i][0] == r.data[0][0]) {
              found_idx = i
              break
            }
            // parsed_h_headers.push(r.data[i][0])
          }
          // this.parsed_h_headers = parsed_h_headers
          console.log({found_idx})
          if (found_idx) {
            // n개

            const rows = [
              [],
            ]

            for(let i=0; i<=r.data.length/found_idx; i++) {
              const start = i*found_idx
              const end = (i+1)*found_idx
              const chunk = r.data.slice(start, end)

              // set header
              if (i == 0) {
                for (let k=start; k<end; k++) {
                  rows[0][k] = r.data[k][0]
                }
              }


              for (let ri = start; ri<end; ri++) {
                if (!r.data[ri]) continue

                for (let j=1; j<r.data[ri].length; j++) {
                  const row_offset = i+1
                  if (!rows[row_offset]) rows[row_offset] = []
                  rows[row_offset].push(r.data[ri][j])
                }
              }

            }
            r.data = rows
            this.results = r

          } else {
            // 1개 짜리
            const rows = [
              [],
            ]
            for (let i=0; i<r.data.length; i++) {
              rows[0][i] = r.data[i][0]
              let last_j = 1
              for (let j=1; j<r.data[i].length; j++) {
                if (!rows[j]) rows[j] = []
                // if (this.parsed_h_headers_hint.length) {
                //   // use hint
                //   if (this.parsed_h_headers_hint.includes(rows[i][j][0])) {
                //     rows[i][j].push(r.data[i][j])
                //   } else {
                //     rows[j][last].push(r.data[i][j])
                //   }
                // } else {
                //   rows[j].push(r.data[i][j])
                // }
                // last_j = j
                rows[j].push(r.data[i][j])
              }
            }
            r.data = rows
            this.results = r
          }
          // if (!found_idx) throw new Error('세로줄 항목 ')
        }
      } else {
        this.results = r
      }



      // if (this.results.data && this.results.data[0]) {
      //   for (let i=0; i<this.results.data[0].length; i++) {
      //     this.import_cols[i] =
      //   }
      // }
      const missing_fields = ['@고객이름', '@고객연락처', '@고객이메일', '@고객아이디']
      this.warning_header = []
      if (this.results && this.results.data) {
        if (this.apply_header == 'Y') {
          this.parsed_headers = this.results.data[0]
          this.results.data = this.results.data.slice(1)

          this.warning_header = []
          missing_fields.forEach(e => {
            if (!this.parsed_headers.includes(e)) {
              if (e == '@고객연락처')
                this.warning_header.push({
                  col: e,
                  message: '@고객전화번호 항목에 정보가 있어야 문자, 카카오알림톡 발송이 가능합니다. ',
                  active: false,
                })
              else if (e == '@고객이메일')
                this.warning_header.push({
                  col: e,
                  message: '@고객이메일 항목에 정보가 있어야 이메일 발송이 가능합니다.',
                  active: false,
                })
              else if (e == '@고객아이디')
                this.warning_header.push({
                  col: e,
                  message: '@고객아이디가 중복이면 새 내용으로 덮어씌웁니다.',
                  active: false,
                })
              else
                this.warning_header.push({
                  col: e,
                  message: e + ' 항목이 없습니다.',
                  active: false,
                })
            }
          })
        }
      }

      for (const f of missing_fields) {
        if (this.parsed_headers_mapping[f]) {
          if (!this.parsed_headers.includes(this.parsed_headers_mapping[f])) {
            this.parsed_headers_mapping[f] = undefined
          }
        }
      }
      if (this.apply_header == 'Y') {
        this.$modal.show('preview')
      } else {
        this.$modal.show('dialog', {
          title: '알림',
          text: '데이터를 가져왔습니다. 아래에 항목을 선택해주세요.',
        })
      }
      this.step = 3
    },
    async submit() {
      try {
        this.saving = true

        this.saved_text = '저장 중'

        if (this.apply_header) {
          const col_labels = this.cols.map(e => e.label)
          const defined_headers = this.parsed_headers.filter( e => {
            return !['@고객이름', '@고객연락처', '@고객이메일', '@고객아이디'].includes(e)
          }).filter( e => {
            return !Object.values(this.parsed_headers_mapping).includes(e)
          }).filter( e => {
            return !col_labels.includes(e)
          })

          const new_cols = [].concat(this.cols)

          for(const h of defined_headers) {
            new_cols.push({
              key: 'd' + ++this.last_col_id,
              label: h,
              format: 'text',
              editable: true,
              position: 'hidden',
            })
          }

          const document = Object.assign({}, this.document, {
            config: Object.assign({}, this.document.config, {
              cols: new_cols,
              last_col_id: this.last_col_id,
            })
          })
          const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}`, document)
          if (r?.data?.message != 'ok') throw new Error('목록 항목 저장 실패 (고객데이터 추가전에 해당목록 항목을 먼저 저장이 필요합니다.)')

          if(!confirm(`${this.results.data.length}건을 추가할까요?`)) throw new Error('취소되었습니다.')

          let count_saved = 0
          for (const d of this.results.data) {
            const row = {}
            for (let i=0; i<d.length; i++) {
              const label = this.parsed_headers[i]
              // console.log({label, new_cols})
              let col
              if (defined_headers.includes(label)) {
                col = new_cols.filter(e => e.label == label)[0]
              } else {
                // col = new_cols.filter(e => e.format == label)[0]
                col = this.cols.filter(e => e.label == label)[0]
                if (!col) {
                  col = this.cols.filter(e => e.format == label)[0]
                }
              }
              if (this.parsed_headers_mapping_by_excel[label]) {
                const f = this.parsed_headers_mapping_by_excel[label]
                // console.log(f)
                col = this.cols.filter(e => e.format == f)[0]
              }

              row[col.key] = String(d[i] || '')
            }
            // console.log(new_cols)
            // console.log(row)
            const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records`, {
              row,
            }, {
              params: {
                apply_upsert: this.apply_upsert,
              }
            })
            if (r?.data?.message != 'ok') throw new Error(`고객 저장 실패 (${count_saved+1}/${this.results.data.length}) ` + r?.data?.message)
            count_saved++
          }

          await this.$store.dispatch('documents', this.property_id)
        } else {
          // 엑셀 검증
          const cols_by_key = {}
          for (const col of this.cols) {
            cols_by_key[col.key] = col
          }
          // for (const col of this.cols) {
          //   if (this.import_cols[col.key])
          // }
          if (Object.keys(this.import_cols).length === 0) throw new Error('엑셀 데이터에 맞는 항목을 선택해주세요.')
          // if (Object.keys(this.import_cols).length < this.results.data[0].length) throw new Error('엑셀 데이터에 맞는 항목을 모두 선택해주세요.')
          const used = {}
          for (let i=0; i<this.results.data[0].length; i++) {
            if (this.import_cols[i] == 'SKIP') continue
            const chosen = this.import_cols[i]
            if (!cols_by_key[chosen]) {
              throw new Error(`${+i + 1}번째 항목이 선택되지 않았습니다.`)
              // break
            }
            if (used[chosen])
              throw new Error(`[${cols_by_key[chosen].label}] 항목이 중복 선택되어있습니다.`)

            used[chosen] = true
          }
          if(!confirm(`${this.results.data.length}건을 추가할까요?`)) throw new Error('취소되었습니다.')

          for (const d of this.results.data) {
            const row = {}
            for (const index of Object.keys(this.import_cols)) {
              if (this.import_cols[index] == 'SKIP') continue
              const col = cols_by_key[this.import_cols[index]]
              // todo check
              if (col) {
                row[col.key] = String(d[index] || '')
              }
            }
            // for (const [index, col] of Object.entries(this.cols)) {
            //   row[col.key] = String(d[index] || '')
            // }
            // console.log('>>', row)
            const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records`, {
              row,
            })
            if (r?.data?.message != 'ok') throw new Error('고객 저장 실패')
          }
        }
        this.saved_text = '저장됨'


        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.document_id}/${this.$options.filters.encodeText(this.document.name)}`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
        this.saved_text = '여러건 저장하기'
      }
      setTimeout(() => {
        this.saving = false
      }, 300);
    },
  },
}
</script>
